@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');

:root {
  --space-small: 1rem;
  --space-medium: 2rem;
  --space-large: 3rem;
  
  --grid-gap: 1rem;
  --grid-gap-small: 0.5rem;  
  
  --swatch-pink-light: #FBEFF2;
  --swatch-white: #fff;
  --swatch-black: #000;
  
  --border-radius: 8px;
  
  --container-max-width: 90rem;
  --container-padding: 2rem;  

  --site--width-inner: calc(var(--site--width) - (var(--site--margin) * 2));
  --site--gutter-total: calc(var(--site--gutter) * (var(--site--column-count) - 1));
  --site--grid-width: min(var(--site--width-inner), var(--container--full));
  --container--main: calc(var(--column-width) * var(--site--column-count) - var(--site--gutter));
  --grid-main: repeat(var(--site--column-count), minmax(0, 1fr));
  --grid-1: repeat(1, minmax(0, 1fr));
  --grid-2: repeat(2, minmax(0, 1fr));
  --grid-4: repeat(4, minmax(0, 1fr));    
  --site--gutter: 0.5rem;

  /* fallback for older browsers */
  --site--margin: 3rem;
  --size-1-25rem: 1.25rem;
	--size--2rem: 2rem;
	--size--2-5rem: 2.5rem;
	--size--3rem: 3rem;
	--size--3-5rem: 3.5rem;
	--size--4rem: 4rem;
	--size--4-5rem: 4.5rem;
	--size--5rem: 5rem;
	--size--5-5rem: 5.5rem;
	--size--6rem: 6rem;
	--size--6-5rem: 6.5rem;
	--size--7rem: 7rem;
	--size--7-5rem: 7.5rem;
	--size--8rem: 8rem;
	--size--8-5rem: 8.5rem;
	--size--9rem: 9rem;
	--size--9-5rem: 9.5rem;
	--size--10rem: 10rem;
	--size--11rem: 11rem;
	--size--12rem: 12rem;
	--size--13rem: 13rem;
	--size--14rem: 14rem;
	--size--15rem: 15rem;
	--size--16rem: 16rem;
  --h1--font-size: 7rem;
  --h2--font-size: 2.5rem;


  min-width: 100%;
  font-size: 1rem;
}

@media screen and (max-width: 767px) {
	:root {
		--site--margin: 1rem;
    --size-1-25rem: 1.5rem;
		--size--2rem: 1.75rem;
		--size--2-5rem: 2rem;
		--size--3rem: 2.25rem;
		--size--3-5rem: 2.375rem;
		--size--4rem: 2.5rem;
		--size--4-5rem: 2.75rem;
		--size--5rem: 3rem;
		--size--5-5rem: 3.25rem;
		--size--6rem: 3.5rem;
		--size--6-5rem: 3.75rem;
		--size--7rem: 4rem;
		--size--7-5rem: 4.25rem;
		--size--8rem: 4.5rem;
		--size--8-5rem: 4.75rem;
		--size--9rem: 5rem;
		--size--9-5rem: 5.25rem;
		--size--10rem: 5.5rem;
		--size--11rem: 5.75rem;
		--size--12rem: 6rem;
		--size--13rem: 6.5rem;
		--size--14rem: 7rem;
		--size--15rem: 7.5rem;
		--size--16rem: 8rem;
    --h1--font-size: 3.5rem;
    --h2--font-size: 1.75rem;
	}
}

/* fluid sizes */
@supports (font-size: clamp(1rem, 0.5rem + 3vw, 3rem)) {
	:root {
		--site--margin: clamp(1rem, 0.428rem + 2.86vw, 3rem);
    --size-1-25rem: clamp(1.5rem, 1.5714rem + -0.3571cqw, 1.25rem);
		--size--2rem: clamp(1.75rem, 1.678rem + 0.36vw, 2rem);
		--size--2-5rem: clamp(2rem, 1.858rem + 0.71vw, 2.5rem);
		--size--3rem: clamp(2.25rem, 2.036rem + 1.07vw, 3rem);
		--size--3-5rem: clamp(2.375rem, 2.053rem + 1.61vw, 3.5rem);
		--size--4rem: clamp(2.5rem, 2.072rem + 2.14vw, 4rem);
		--size--4-5rem: clamp(2.75rem, 2.25rem + 2.5vw, 4.5rem);
		--size--5rem: clamp(3rem, 2.428rem + 2.86vw, 5rem);
		--size--5-5rem: clamp(3.25rem, 2.608rem + 3.21vw, 5.5rem);
		--size--6rem: clamp(3.5rem, 2.786rem + 3.57vw, 6rem);
		--size--6-5rem: clamp(3.75rem, 2.964rem + 3.93vw, 6.5rem);
		--size--7rem: clamp(4rem, 3.142rem + 4.29vw, 7rem);
		--size--7-5rem: clamp(4.25rem, 3.322rem + 4.64vw, 7.5rem);
		--size--8rem: clamp(4.5rem, 3.5rem + 5vw, 8rem);
		--size--8-5rem: clamp(4.75rem, 3.678rem + 5.36vw, 8.5rem);
		--size--9rem: clamp(5rem, 3.858rem + 5.71vw, 9rem);
		--size--9-5rem: clamp(5.25rem, 4.036rem + 6.07vw, 9.5rem);
		--size--10rem: clamp(5.5rem, 4.214rem + 6.43vw, 10rem);
		--size--11rem: clamp(5.75rem, 4.25rem + 7.5vw, 11rem);
		--size--12rem: clamp(6rem, 4.286rem + 8.57vw, 12rem);
		--size--13rem: clamp(6.5rem, 4.642rem + 9.29vw, 13rem);
		--size--14rem: clamp(7rem, 5rem + 10vw, 14rem);
		--size--15rem: clamp(7.5rem, 5.358rem + 10.71vw, 15rem);
		--size--16rem: clamp(8rem, 5.714rem + 11.43vw, 16rem);

    --font-size-base: clamp(1rem, 1vw + 1rem, 1.5rem);
    --font-size-h1: clamp(3.5rem, 3vw + 1rem, 7rem);
    --font-size-h2: clamp(1.75rem, 1.5357rem + 1.0714cqw, 2.5rem);
    --font-size-large: clamp(1.5rem, 1vw + 1rem, 2rem);
    --font-size-paragraph: clamp(1.75rem, 1.6786rem + 0.3571cqw, 2rem);
	}
}

#root {
  min-width: 100svw;
}

body {
  background-color: var(--swatch-pink-light);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: var(--swatch-black);  
  line-height: 1.5;
  overscroll-behavior: none;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6, p, label, figure, blockquote {
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	margin-top: 0;
	margin-bottom: 0;
}

h1 {
  font-size: var(--font-size-h1);
  line-height: 1.2;
  text-align: center;  
  text-wrap: balance;
  font-family: 'Parisienne', cursive;
}

h2 {
  font-size: var(--font-size-h2);
}

p {
  font-size: var(--size-1-25rem);
  line-height: 1.5;
  text-align: center;
}

.page_wrap {
  overflow: clip;
  width: 100%;
}

.u-container {
  max-width: var(--container-max-width);
  margin-left: auto;
  margin-right: auto;
  padding: var(--container-padding);
  max-width: 100%;
}

.is--min-100svh {
  min-height: 100svh;
}

.u-vflex-center-top {
  flex-flow: column;
  place-content: center flex-start;
  align-items: center;
  display: flex;
}
.u-vflex-stretch-top {
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.u-grid-column-2 {
  grid-column-gap: var(--site--gutter);
  grid-row-gap: var(--site--gutter);
  grid-template-rows: auto;
  grid-auto-columns: minmax(0, 1fr);
  grid-template-columns: var(--grid-2);
  display: grid;
}

.u-grid-column-4 {
  grid-column-gap: var(--site--gutter);
  grid-row-gap: var(--site--gutter);
  grid-template-rows: auto;
  grid-auto-columns: minmax(0, 1fr);
  grid-template-columns: repeat(4, minmax(0, 1fr));
  display: grid;
}

.u-gap-small {
  grid-column-gap: var(--space--small);
  grid-row-gap: var(--space--small);
}

.u-gap-medium {
  grid-column-gap: var(--space--medium);
  grid-row-gap: var(--space--medium);
}

.u-gap-large {
  grid-column-gap: var(--space--large);
  grid-row-gap: var(--space--large);
}

.u-text-align-center {
  text-align: center;
}

.u-text-large {
  font-family: var(--text-large--font-family);
  font-size: 4.5rem;
  line-height: var(--text-large--line-height);
  letter-spacing: var(--text-large--letter-spacing);
  font-weight: var(--text-large--font-weight);
  text-transform: var(--text-large--text-transform);
  text-wrap: pretty;
}

.u-text-main {
  font-family: var(--text-main--font-family);
  font-size: var(--text-main--font-size);
  line-height: var(--text-main--line-height);
  letter-spacing: var(--text-main--letter-spacing);
  font-weight: var(--text-main--font-weight);
  text-transform: var(--text-main--text-transform);
  text-wrap: pretty;
}

.gallery-hero__content-wrp {
  margin-bottom: 2rem;
  margin-top: 3rem;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: var(--grid-gap);
  padding: var(--space-medium);
}

.image-grid img {
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
  cursor: pointer;
  object-fit: cover;
}

.gallery-grid__cl-wrp {
  width: 100%;
}

.gallery-grid__c-list-masonry {
  display: flex;
  margin-left: -10px; /* Matches the gutter in Masonry */
  width: auto;
}
.gallery-grid__c-item-masonry {
  margin-bottom: 10px;
  padding-left: 10px; /* Matches the gutter in Masonry */
}

.gallery-grid__c-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust size as needed */
  grid-auto-flow: dense;
  grid-auto-rows: 175px;
  grid-gap: 0.75rem;
}

/* Grid item */
.gallery-grid__item {
  position: relative;
  overflow: hidden; /* Ensure images don't overflow the grid item */
  display: block;
}

/* Stretch images within the grid items */
.gallery-grid__item img {
  position: absolute;
  left: auto;
  top: 0%;
  right: auto;
  bottom: 0%;
  width: 100%;
  height: 100%;
  max-width: none;
  object-fit: cover;
  object-position: 50% 50%;
}

.thumbnail {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;  
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 991px) {
  .u-grid-column-4, .gallery-grid__c-list {
    grid-template-columns: repeat(3, 1fr); /* Switch to 2 columns on medium screens */
  }
}

@media screen and (max-width: 767px) {
  .u-grid-column-4, .gallery-grid__c-list {
    grid-template-columns: repeat(2, 1fr); /* Switch to 2 columns on medium screens */
  }

  .gallery-grid__c-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust size as needed */
    grid-auto-flow: dense;    
    grid-gap: 0.75rem;
  }
  
}

@media screen and (max-width: 575px) {
  .u-grid-column-4, .gallery-grid__c-list {
    grid-template-columns: 1fr; /* Full width on small screens */
  }

  .gallery-grid__c-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust size as needed */
    grid-auto-flow: dense;
    grid-auto-rows: 275px;
    grid-gap: 0.75rem;
  }
  
  .u-container {        
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 3rem;
    padding-bottom: 4rem;
  }

  .pswp--touch .pswp__button--arrow {
    visibility: visible !important;    
  }
  .pswp__button.pswp__button--arrow--next.pswp__button--arrow,
  .pswp__button.pswp__button--arrow--prev.pswp__button--arrow {
    opacity: 0.7;
  }
}

/* Download button styles */
.pswp__button--download {
  background: none;
  border: none;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

.pswp__button--download:hover {
  color: #ff0;
}

.password-protect-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  grid-column-gap: var(--site--gutter);
  grid-row-gap: var(--size-1-25rem);
  padding: 0 1rem;
  text-align: center;
}

.password-input {
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid black;
  width: 100%;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  border-radius: 8px;
}

.submit-btn:hover {
  background-color: #555;
}

form {
  width: 100%;
  max-width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  grid-column-gap: var(--site--gutter);
  grid-row-gap: var(--site--gutter);
}

/* Optional: Add transition effect */
.lazyload {
  opacity: 0;
  transition: opacity 300ms;
}

.lazyloaded {
  opacity: 1;
}